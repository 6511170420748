.positions-cell {
  position: relative;

  .cell {
    position: relative;
  }

  .positions-toggle {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "TT Norms", sans-serif;
    border-bottom: 1px solid #019a97;
    display: block;
    margin: 20px 0;
    padding-right: 40px;
  }
  .posititions-toggle-icon.dashicons {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 15px;
    right: 5px;
    font-size: 32px;
    color: #019a97;
    transition: all 0.5s ease-in;
    pointer-events: none;
  }
}
