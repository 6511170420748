.mountain-divider {
  background-image: url(../images/mountains-divider.png);
  background-position: top center;
  background-repeat: no-repeat;
  height: 155px;
}

.lines-divider-bg {
  background-image: url(../images/background-lines-rect.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
  width: 100%;
  bottom: 0;
  position: absolute;
  border-top: 1px solid #eee;
}

.divider-diagonal {
  position: relative;
}
.divider-diagonal::before {
  content: '';
  padding-top: 100px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background-image: linear-gradient(3deg, #fff calc(50% - 2px), transparent 50%);
}
.divider-diagonal.lightwhite::before {

  /* background-image: linear-gradient(3deg, #e7f6f6 calc(50% - 2px), transparent 50%); */
  background-image: linear-gradient(3deg, #f3f6f7 calc(50% - 2px), transparent 50%);
}
.divider-diagonal.lightgreen::before {
  background-image: linear-gradient(3deg, #019A97 calc(50% - 2px), transparent 50%);
}
.divider-diagonal.darkgreen::before {
  background-image: linear-gradient(3deg, #098D8A calc(50% - 2px), transparent 50%);
}

.divider-diagonal.divider-diagonal-inverse.white::before {
  background-image: linear-gradient(3deg, transparent calc(50% - 2px), #fff 50%);
  bottom: auto;
}
.divider-diagonal.divider-diagonal-inverse.lightwhite::before {
  
  /* background-image: linear-gradient(3deg, transparent calc(50% - 2px), #e7f6f6 50%); */
  background-image: linear-gradient(3deg, transparent calc(50% - 2px), #f3f6f7 50%);
  bottom: auto;
}
.divider-diagonal.divider-diagonal-inverse.lightgrey::before {
  background-image: linear-gradient(3deg, transparent calc(50% - 2px), #eee 50%);
  bottom: auto;
}
