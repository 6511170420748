.btn-quote {
  background-image: url(../images/quote-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 7px;
  color: #fff !important;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-white:hover {
  background: #019A97;
  color: #fff;
}
.btn-white {
  background: #fff;
  padding: 10px 40px;
  color: #019A97;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TT Norms', sans-serif;
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.1em;

  border: 3px solid;
  border-image-source: linear-gradient(to right, #02a09d, #00cfcb);
  border-image-slice: 30%;

  /* border: 3px solid #019A97; */
}

.btn-white-white:hover {
  background: #fff;
  color: #019A97;
}
.btn-white-white {
  background: #019A97;
  padding: 10px 40px;
  border: 3px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TT Norms', sans-serif;
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.btn-white-transparent {
  padding: 10px 40px;
  border: 3px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TT Norms', sans-serif;
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.btn-transparent:hover {
  background: #fff;
  color: #019A97;
}
.btn-transparent {
  padding: 10px 40px;
  color: #019A97;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TT Norms', sans-serif;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.1em;

  border: 3px solid;
  border-image-source: linear-gradient(to right, #02a09d, #00cfcb);
  border-image-slice: 30%;

  /* border: 3px solid #019A97; */
}

.btn-clear:hover {
  background: #019A97;
  color: #fff;
}
.btn-clear {
  background: none;
  padding: 10px 40px;
  color: #019A97;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TT Norms', sans-serif;
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.1em;

  border: 3px solid;
  border-image-source: linear-gradient(to right, #02a09d, #00cfcb);
  border-image-slice: 30%;

  /* border: 2px solid #019A97; */
}
