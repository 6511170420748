.angled-bottom-grid h2 {
  font-size: 36px;
  font-weight: 700;
}
.angled-bottom-grid img {
  padding: 50px 0;
}

.charter-types-grid .row-standard-blocks-main .cell {
  margin-bottom: 30px;
}
.charter-types-grid .row-standard-blocks-main .cell > div {
  background: #fff;
  overflow: hidden;
}
.charter-types-grid .row-standard-blocks-main .cell img {
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.charter-types-grid .row-standard-blocks-main .cell img:hover {
  transform: scale(1.1);
}
.charter-types-grid .row-standard-blocks-main .cell img ~ p {
  margin-bottom: 0;
}
.charter-types-grid .row-standard-blocks-main .cell .style-wrapper {
  padding: 20px;
  background: #fff;
}
.charter-types-grid .row-standard-blocks-main .cell .style-wrapper p {
  font-size: 18px;
}
.charter-types-grid .row-standard-blocks-main .cell .style-wrapper a {
  font-size: 14px;
  letter-spacing: 0.1em;
}
.charter-types-grid .row-standard-blocks-main .cell .style-wrapper a:hover {
  letter-spacing: 0.15em;
}

.entry.our-vehicles-grid {
  .grid-container {
    border-bottom: 1px solid #ccc;
    padding-top: 40px;
    padding-bottom: 20px;

    &.no-border {
      border: none;
    }

    .row-standard-content-row .cell p {
      overflow: hidden;
    
      img {
        width: 100%;
        transition: all 0.2s ease-in-out;
      
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.entry.available-positions {
  background: #fff;
  margin-top: 20px;
  padding-bottom: 0;

  @include breakpoint(small) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 39.9375em) {
  .icons-grid .cell {
    padding-bottom: 60px;
  }
}
