.animated-mountains {
  position: relative;
  height: 200px;
}
.animated-mountains .mountain1 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain1.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 931px;
  height: 180px;
  top: 0;
  left: -380px;
}
.animated-mountains .mountain2 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1349px;
  height: 124px;
  top: 0;
  left: -450px;
}
.animated-mountains .mountain3 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain3.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 212px;
  height: 42px;
  top: 100px;
  right: 600px;
}
.animated-mountains .mountain4 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain4.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 212px;
  height: 42px;
  top: 70px;
  right: 380px;
}
.animated-mountains .mountain5 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain5.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 823px;
  height: 122px;
  top: 0;
  right: -350px;
}
.animated-mountains .mountain6 {
  display: inline-block;
  position: absolute;
  background-image: url(../images/anim-mountain6.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 773px;
  height: 176px;
  top: 30px;
  right: -450px;
}
