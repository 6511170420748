@font-face {
  font-family: 'TT Norms';
  src: url(../fonts/TTNorms-Regular.woff) format('woff'),
       url(../fonts/TTNorms-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TT Norms';
  src: url(../fonts/TTNorms-Bold.woff) format('woff'),
       url(../fonts/TTNorms-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: bold;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'TT Norms', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
}
h2 {
  line-height: 45px;

  @include breakpoint(small) {
    line-height: 24px;
  }
}

a {
  color: #fff;
  transition: all 0.2s ease-in;
}
a:hover {
  color: #d1d1d1;
}
a:focus {
  color: #fff;
  outline: none;
}

p {
  font-size: 21px;
}

.color-white,
.link-white a,
.header-white h1, 
.header-white h2, 
.header-white h3,
.header-white h4,
.header-white h5,
.header-white h6 {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.header-white h2::after {
  content: " ";
  background: #fff;
  height: 3px;
  width: 90px;
  display: block;
  margin: 10px 0;
  margin-bottom: 15px;
}

.bg-lightwhite {
  
  /* background-color: #E7F6F6; */
  background-color: #f3f6f7;
}

.color-grey {
  color: #636466;
}
.bg-grey {
  background-color: #636466;
}
.bg-lightgrey {
  background-color: #eee;
}

.color-darkgreen,
.link-darkgreen a,
.header-darkgreen h1, 
.header-darkgreen h2, 
.header-darkgreen h3,
.header-darkgreen h4,
.header-darkgreen h5,
.header-darkgreen h6 {
  color: #019A97;
}
.bg-darkgreen {
  background-color: #098D8A;
}

.header-darkgreen h2::after,
.header-darkgreen h4::after {
  
  /* background: #019A97; */
  background: linear-gradient(to right, #02a09d, #00cfcb);
  content: " ";
  height: 4px;
  width: 90px;
  display: block;
  margin: 10px 0;
  margin-bottom: 15px;
}

.inline-header-darkgreen {
  color: #019A97;
  font-weight: 700;
  font-size: 21px;
}

.color-lightgreen,
.link-lightgreen a,
.header-lightgreen h1, 
.header-lightgreen h2, 
.header-lightgreen h3,
.header-lightgreen h4,
.header-lightgreen h5,
.header-lightgreen h6 {
  color: #019A97;
}
.bg-lightgreen {
  background-color: #019A97;
}

.text-bold {
  font-weight: 700;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  h1, h2, h3, h4, h5, h6 {
    word-break: break-word;
  }
}
