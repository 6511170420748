
.wpcf7-form {
  h4 {
    margin-top: 50px;
    margin-bottom: 30px;

    &:first-of-type {
      margin-top: 0;
    }

    &::after {
      content: " ";
      background: #019a97;
      height: 3px;
      width: 90px;
      display: block;
      margin: 10px 0 15px;
    }
  }

  label {
    font-weight: 700;
    color: #636466;
  }

  span.wpcf7-not-valid-tip {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  [type="color"],
  [type="date"],
  [type="datetime-local"],
  [type="datetime"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  select,
  textarea {
    border-radius: 7px;
  }

  [type="submit"] {
    cursor: pointer;
  }

  .wpcf7-form-control {
    &.form-time {
      width: 50px;
      display: inline-block;
    }

    &.form-ampm {
      width: 80px;
      margin-left: 10px;
    }

  }
}
