.owl-carousel .owl-stage-outer {
  margin-left: 80px;
  margin-right: 80px;

  @media only screen and (max-width: 375px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.owl-carousel.testimonials-carousel {
  margin-top: 80px;
}
.owl-carousel.testimonials-carousel .testimonials-wrapper {
  margin-right: 170px;

  @media only screen and (max-width: 375px) {
    margin-right: 0;
  }
}
.owl-carousel.testimonials-carousel .testimonials-wrapper img {
  width: 163px;
  float: left;
}
.owl-carousel.testimonials-carousel .testimonials-wrapper .testimonials-content {
  padding-top: 30px;
  margin-left: 170px;
  padding-bottom: 20px;
  border-bottom: 1px solid #019F9C;
}
.owl-carousel.testimonials-carousel .testimonials-wrapper .testimonials-content .author {
  font-weight: 700;
  text-transform: uppercase;
  color: #019F9C;
  font-size: 14px;
  font-family: 'TT Norms', sans-serif;
}

.owl-carousel.testimonials-carousel .owl-nav {
  @media only screen and (max-width: 375px) {
    display: none;
  }
}

.owl-carousel.testimonials-carousel .owl-nav .owl-prev {
  background-image: url(../images/arrow-left-testimonials.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: #fff;
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  transition: all 0.2s ease-in;
}
.owl-carousel.testimonials-carousel .owl-nav .owl-prev:hover,
.owl-carousel.testimonials-carousel .owl-nav .owl-next:hover {
  transform: scale(1.1);
}
.owl-carousel.testimonials-carousel .owl-nav .owl-next {
  background-image: url(../images/arrow-right-testimonials.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: #fff;
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  top: 40px;
  right: 0;
  transition: all 0.2s ease-in;
}
.owl-theme .owl-dots {
  @media only screen and (max-width: 375px) {
    margin-top: 10px;
  }
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #019F9C;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .owl-carousel.owl-carousel.mobile-images-carousel .owl-stage-outer {
    margin: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .owl-carousel.mobile-images-carousel .owl-stage-outer {
    margin: 0;
  }

  .owl-carousel.testimonials-carousel .testimonials-wrapper {
    text-align: center;
  }
  .owl-carousel.testimonials-carousel .testimonials-wrapper img {
    float: none;
    display: inline;
  }
  .owl-carousel.testimonials-carousel .testimonials-wrapper .testimonials-content {
    margin-left: 0;
  }
  .owl-carousel.testimonials-carousel .owl-nav .owl-prev,
  .owl-carousel.testimonials-carousel .owl-nav .owl-prev:hover,
  .owl-carousel.testimonials-carousel .owl-nav .owl-next,
  .owl-carousel.testimonials-carousel .owl-nav .owl-next:hover {
    top: 50%;
    transform: translateY(-50%);
  }
}
