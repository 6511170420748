.entry.icons-grid {
  padding: 80px 0;
  padding-bottom: 120px;
  position: relative;

  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.entry.icons-grid .icons-grid-image-wrapper {
  height: 75px;
}
.entry.icons-grid h4 {
  margin-top: 15px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
.entry.icons-grid p {
  padding-bottom: 15px;
  font-size: 19px;
}
.entry.icons-grid a {
  font-size: 14px;
  font-family: 'TT Norms', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.entry.icons-grid a::after {
  content: " ";
  width: 90px;
  height: 3px;
  
  /* background: #fff; */
  background: linear-gradient(to right, #02a09d, #00cfcb);
  display: block;
  margin-top: 8px;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .entry.icons-grid p {
    font-size: 16px;
  }
}
