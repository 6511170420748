.nav-top {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear; 
}
.nav-main {
  float: right;
  margin-top: 30px;
}
.nav-main,
.nav-top {
  text-align: right;
}
.nav-main ul,
.nav-top ul {
  display: inline-block;
}
.nav-main li,
.nav-top li {
  display: inline-block;
  position: relative;
}

.nav-top a {
  font-family: 'TT Norms', sans-serif;
  border-right: 1px solid #fff;
  padding: 0 15px;
  font-weight: 400;
}
.nav-top ul >:last-child a {
  border-right: 0;
  padding-right: 0;
}
.nav-main a {
  font-family: 'TT Norms', sans-serif;
  padding-right: 43px;
  font-size: 18px;
}
.nav-main > ul >:last-child a {
  background-image: url(../images/quote-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 7px;
  font-weight: 700;
  text-transform: uppercase;
}
.nav-main > ul >:last-child a:hover {
  transform: scale(1.05);
}

.nav-main ul li.menu-item-has-children {
  padding-bottom: 20px;
}
.nav-main ul li ul.sub-menu {
  display: none;
  position: absolute;
  z-index: 200;
  top: 45px;
  right: 50px;
  left: 0;
  min-width: 260px;
  text-align: left;
  background: rgba(0,0,0,0.8);
  padding-bottom: 10px;
  padding-top: 20px;
  font-size: 14px;
  margin-left: -20px;
}
.nav-main ul li .sub-menu li {
  display: block;
  padding-bottom: 10px;
  letter-spacing: 1px;
}
.nav-main ul li .sub-menu li a {
  padding-left: 20px;
  white-space: nowrap;
  font-size: 14px;
}

@media only screen and (max-width: 1030px) {
  .nav-main a {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 960px) {
  .nav-main a {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 940px) {
  .nav-main a {
    font-size: 16px;
  }
  .nav-main>ul>:last-child a {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 880px) {
  .nav-main a {
    font-size: 15px;
  }
  .nav-main ul >:last-child a {
    padding: 10px;
  }
}

@media only screen and (max-width: 835px) {
  .nav-main a {
    font-size: 14px;
    padding-right: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .nav-top a,
  .nav-main a,
  .nav-main ul >:last-child a {
    display: none;
  }
}
