// ----------------------
// BREAKPOINTS
// ----------------------
$breakpoints: (
  small: 640px,
  medium: 1024px,
  large: 1200px
);
$breakpoint-classes: (small medium large);

@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media all and (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$size}` in $breakpoints.";
  }
}
