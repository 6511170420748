.two-col-grid {
  padding-top: 80px;
}
.two-col-grid h2 {
  padding-top: 30px;
}
.two-col-grid .overlap-images-wrapper {
  position: relative;
  height: 680px;
  clear: both;
}
.two-col-grid .overlap-images-1 {
  left: 100px;
  top: 0;
  position: absolute;
  display: block;
  z-index: 10;
}
.two-col-grid .overlap-images-2 {
  left: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 100;
}
.two-col-grid .overlap-right .overlap-images-2 {
  left: auto;
  right: 0;
}
