.anim-zoom img {
  transition: all 0.2s ease-in-out;
}
.anim-zoom:hover img {
  transform: scale(1.02);
}

.anim-zoom-10 {
  overflow: hidden;
}
.anim-zoom-10 img {
  transition: all 0.2s ease-in-out;
}
.anim-zoom-10:hover img {
  transform: scale(1.1);
}

.anim-rotate180 {
  transform: rotate(180deg);
}
