.page-content {
  position: relative;
}

.entry.charter-types-grid {
  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.entry.who-worked-with-row {
  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.worked-with-image {
  transition: all 0.3s linear;
}

.padding-small-bottom {
  padding-bottom: 30px;
}
.padding-medium {
  padding: 80px 0;
}
.padding-medium-bottom {
  padding-bottom: 80px;
}
.padding-large {
  padding: 130px 0;
}
.padding-large-bottom {
  padding-bottom: 130px;
}

.container-bottom-80 {
  .grid-container {
    padding-bottom: 80px;

    @include breakpoint(small) {
      padding-bottom: 0;
    }
  }
}

.top-30 {
  margin-top: 30px;
}

.bottom-60 {
  margin-bottom: 60px;
}
.bottom-80 {
  margin-bottom: 80px;
}

.border-radius-top {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.bg-started {
  background-image: url(../images/bkg-texture2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bkg-auto {
  background-size: auto !important;
}

.img-border-radius img {
  border-radius: 7px;
}

.nowrap {
  white-space: nowrap;
}
