.entry {
  &.row-standard,
  &.row-overlapping-images,
  &.row-testimonials,
  &.available-positions {
    @include breakpoint(small) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.no-padding {
      @include breakpoint(small) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.icons-grid {
      @include breakpoint(small) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .row-standard-header-row {
    @include breakpoint(small) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.promo-breadcrumb {
  @include breakpoint(small) {
    padding-left: 20px;
  }
}

/*
.cell.cell-padding-right {
  padding-right: 40px;
}
.cell.cell-padding {
  padding: 0 40px;
}
.cell.cell-padding-left {
  padding-left: 40px;
}

.cell-padding-normal .grid-x .cell {
  padding: 0 40px;
}

.cell-padding-normal .grid-x >:last-child {
  padding-right: 0;
}
.cell-padding-normal .grid-x >:first-child {
  padding-left: 0;
}
*/

.cell.grid-header h2 {
  font-size: 36px;
}
.cell.grid-header p {
  font-size: 21px;
}

.cell .cell-promo {
  width: 100%;
}
.cell .cell-content {
  padding: 20px;
  padding-bottom: 80px;
  background: #fff;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.cell .cell-content h4 {
  font-size: 20px;
}
.cell .cell-content p {
  font-size: 18px;
}
.cell .cell-content a {
  font-size: 14px;
  position: absolute;
  bottom: 50px;
}
.cell .cell-content a::after {
  content: " ";
  width: 90px;
  height: 3px;
  background: #019A97;
  display: block;
  margin-top: 5px;
}

.cell.z-100 {
  z-index: 100;
}

.grid-x.flex-bottom .cell {
  align-self: flex-end;
}

@media screen and (min-width: 40em) {

  /*
  .cell-padding-normal .grid-x >:first-child {
    padding-left: 40px;
  }
  */
}

@media screen and (max-width: 39.9375em) {

  /*
  .cell.cell-padding,
  .cell.cell-padding-right {
    padding: 0;
    padding-bottom: 80px;
  }
  
  .cell.cell-padding-left {
    padding: 0;
  }

  .cell-padding-normal .grid-x .cell {
    padding-bottom: 40px;
  }

  .cell-padding-normal .grid-x >:first-child {
    padding-left: 40px;
  }
  */
}
