#mobilemenu {
  background-image: url(../images/icon-mobilemenu.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 23px;
  right: 25px;
  top: 35px;
  width: 30px;
  background-size: 30px 18px;
  position: absolute;
  display: none;
}

#closemobile {
  font-weight: 700;
  padding: 20px;
  padding-bottom: 10px;
  display: inline-block;
}
#sbrightmenu .menu {
  color: #fff;
  padding: 20px;
}
#sbrightmenu .menu ul {
  list-style: none;
  margin-left: 0;
  width: 100%;
}
#sbrightmenu .menu ul li {
}

body.admin-bar {
  position: inherit;
}

body.admin-bar #sbrightmenu {
  margin-top: 46px;
}

#menu-slidebars-menu {
	color:#fff;
	list-style:none;
	width:100%;
	clear:both;
}
#menu-slidebars-menu li {
	width:100%;
}
#menu-slidebars-menu li a {
	color:#fff;
	padding:15px 0;
	display:block;
  font-family:'Open Sans', sans-serif;
  position: relative;
  padding-left: 45px;
  font-weight: 700;
}

#menu-slidebars-menu > li.menu-item-has-children > a::before {
	content: " ";
	background: url(../icons/icon-quote-arrow-right.png) left top no-repeat;
	position: absolute;
	left: 15px;
	top: 15px;
	transition: all 0.25s ease;
	width: 10px;
	height: 15px;
	background-size: 10px 15px;
}
#menu-slidebars-menu > li.menu-item-has-children > a.active::before {
	transform: rotate(90deg);
}
#menu-slidebars-menu li .sub-menu {
  display: none;
  padding-left: 20px;
}

#menu-slidebars-menu > :last-child a {
  background-image: url(../images/quote-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 7px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 45px;
  margin-top: 30px;

  @media only screen and (max-width: 375px) {
    white-space: nowrap;
  }
}

a#closemobile {
	width:100%;
	text-align:left;
	background:#fff;
  padding:15px 0;
  padding-left: 66px;
	display:block;
  font-family:'Open Sans', sans-serif;
  
  &:hover {
    color: #636466;
  }
}

@media only screen and (max-width: 800px) {
  #mobilemenu {
    display: block;
  }
}
