section.header {
  height: 750px;
  background-image: url(../images/promo.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  text-transform: uppercase;
  background-attachment: fixed;

  @include breakpoint(small) {
    background-attachment: unset;
  }
}

section.header .header-wrapper {
  background: linear-gradient(#020202, transparent);
  padding-top: 25px;

  @include breakpoint(small) {
    padding-left: 20px;
  }

  &.sticky {
    padding-top: 8px;
    padding-bottom: 17px;
    background-image: url(/wp-content/themes/tourlabs-vic/assets/images/bkg-sticky-nav.png);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 200;

    .nav-top {
      visibility: hidden;
      opacity: 0;
    }
  }
}

section.header .header-cell {
  position: relative;
}

.header-logo {
  padding-top: 8px;

  @media only screen and (max-width: 375px) {
    height: 80px;
  }
}
