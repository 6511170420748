.promo-bottom-overlay {
  background-image: url(../images/promo-area-shapes.png);
  background-position: top center;
  background-repeat: no-repeat;
  height: 80px;
  margin-top: -80px;
}

.promo-content {
  padding: 100px 0;
  color: #fff;
  
  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 375px) {
    padding-top: 50px;
  }
}
.promo-content h1 {
  color: #fff;
  font-weight: 700;
  font-size: 56px;
  padding-bottom: 20px;
  line-height: 1.1;
}
.promo-content h1::after {
  content: " ";
  background: #fff;
  width: 90px;
  height: 3px;
  display: block;
  margin-top: 20px;
}
.promo-content p {
  color: #fff;
  font-size: 21px;
  text-transform: none;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .promo-content h1 {
    font-size: 44px;
  }
}

@media screen and (max-width: 39.9375em) {
  .promo-content h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .promo-content h1 {
    font-size: 32px;
  }
}
