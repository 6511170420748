.footer {
  background-color: #0F7573;
}
.footer p {
  font-size: 16px;
}
.footer .footer-grid {
  position: relative;

  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.footer .footer-grid::before {
  content: '';
  padding-top: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background-image: linear-gradient(3deg, #0F7573 calc(50% - 2px), transparent 50%);
}

.footer .footer-background {
  background-image: url(../images/footer-background.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.footer .footer-line {
  width: 100%;
  background: #fff;
  height: 1px;
  opacity: 0.18;
}

.footer .footer-content-grid {
  margin-top: -30px;
  padding-top: 90px;

  @include breakpoint(small) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.footer .footer-content-grid a {
  font-weight: 700;
}

.footer .footer-nav-left {
  list-style: none;
  display: inline-block;
}
.footer .footer-nav-right {
  list-style: none;
  display: inline-block;
  padding-left: 60px;
}

.footer .footer-copy-grid {
  padding-left: 20px;
  padding-right: 20px;
}
.footer .footer-copy-grid p {
  margin-bottom: 0;
  padding-top: 40px;
  font-size: 14px;
}
.footer .footer-copy-grid a {
  font-weight: 700;
}

.footer .footer-content-grid a.link-normal {
  font-weight: 400;
}

.footer .footer-email,
.footer .footer-phone {
  font-weight: 700;
  padding-left: 30px;
  position: relative;
}
.footer .footer-phone::before {
  content: "";
  background-image: url(../images/icon-phone.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.footer .footer-email::before {
  content: "";
  background-image: url(../images/icon-email.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .footer-content-grid .cell.text-right {
    text-align: center;
  }
  .footer .footer-nav-right {
    padding-left: 20px;
  }
}

@media screen and (max-width: 39.9375em) {
  .footer-content-grid .cell {
    padding-bottom: 30px;
  }
  .footer-content-grid .cell.text-right {
    text-align: left;
  }
  .footer .footer-nav-left {
    margin-left: 0;
  }
}

@media only screen and (max-width: 375px) {
  .footer-content-grid .cell.text-right {
    text-align: center;
  }
}
