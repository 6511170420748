.entry.breadcrumb-row {
  border-bottom: 1px solid #00CFCB;
  z-index: 100;
  position: absolute;
  width: 100%;
  padding: 20px 0;
  left: 0;
  right: 0;
  top: 80px;
}

.entry.breadcrumb-row + div {
  padding-top: 150px;
}
