.page-content .angled-bottom-grid::before {
  content: '';
  padding-top: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background-image: linear-gradient(3deg, #fff calc(50% - 2px), transparent 50%);
}

.page-content .angled-bottom-grid {
  width: 100%;
  position: relative;
  padding: 90px 0;
}

.page-content .angled-bottom-grid2 {
  position: relative;
}
.page-content .angled-bottom-grid2::before {
  content: '';
  padding-top: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background-image: linear-gradient(3deg, #fff calc(50% - 2px), transparent 50%);
}

.page-content .angled-bottom-grid3 {
  position: relative;
}
.page-content .angled-bottom-grid3::before {
  content: '';
  padding-top: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background-image: linear-gradient(3deg, #019A97 calc(50% - 2px), transparent 50%);
}
